export enum ApiTag {
  USER = 'User',
  ALLOCATIONS = 'Allocations',
  LISTINGS = 'Listings',
  OFFERS = 'Offers',
  PROJECTS = 'Projects',
  PROJECT_DETAIL = 'ProjectDetails',
  TRANSACTIONS_INVESTMENT = 'TransactionsInvestment',
  FEE = 'Fee',
  TOKEN_DISTRIBUTIONS = 'TokenDistribution',
  UTILS = 'utils',
  TRANSACTIONS = 'Transactions',
  WHITELISTS = 'whitelists',
}

export enum ApiSlice {
  USER = 'User',
  ALLOCATIONS = 'Allocations',
  LISTINGS = 'Listings',
  OFFERS = 'Offers',
  PROJECTS = 'Projects',
  PROJECT_DETAIL = 'ProjectDetails',
  TRANSACTIONS_INVESTMENT = 'TransactionsInvestment',
  TRANSACTIONS = 'Transactions',
  FEE = 'Fee',
  TOKEN_DISTRIBUTIONS = 'TokenDistribution',
  UTILS = 'utils',
  WHITELISTS = 'whitelists',
}
