import ProjectCreateHeader from '@components/project/project-create/ProjectCreateHeader';
import ProjectCreateOverview from '@components/project/project-create/ProjectCreateOverview';
import ProjectCreatePartners from '@components/project/project-create/ProjectCreatePartners';
import ProjectCreateSocials from '@components/project/project-create/ProjectCreateSocials';
import ProjectCreateTeam from '@components/project/project-create/ProjectCreateTeam';
import ProjectCreateValues from '@components/project/project-create/ProjectCreateValues';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import {
  createProjectDetailsInitialValues,
  createProjectInitialValues,
} from '@formik/project/ProjectInitialData';
import {
  CreateProjectDetailsValidationSchema,
  CreateProjectValidationSchema,
} from '@formik/project/ProjectValidation';
import useNotification from '@hooks/useNotification';
import useProject from '@hooks/useProject';
import { Box, Button } from '@mui/material';
import { MainBox } from '@styles/shared/SBoxes';
import { CreateFeeDTO } from '@typescript/dtos/fee/createFee.to';
import {
  CreateProjectDetailsDTO,
  CreateProjectDTO,
  createProjectPayloadZod,
} from '@typescript/dtos/project/createProject.dto';
import { TProjectCreateInitialValues } from '@typescript/models/Project.model';
import { TProjectCreateDetailsInitialValues } from '@typescript/TProjectDetails';
import { MetamaskError } from '@utils/errors';
import { calculate } from '@utils/functions';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ProjectCreate() {
  const navigate = useNavigate();

  const { createProject } = useProject();

  const { notifySuccess, notifyError } = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [projectPayload, setProjectPayload] = useState<CreateProjectDTO>();

  const [projectDetailsPayload, setProjectDetailsPayload] =
    useState<CreateProjectDetailsDTO>();

  const [projectFeePayload, setProjectFeePayload] = useState<CreateFeeDTO>();

  const requestAlreadySent = useRef(false);

  const createFeeAttributes = ({
    feeInPercentage,
    totalTokens,
    tokenPrice,
    lvWallet,
    feeInPercentagePrivate,
  }: TProjectCreateInitialValues) => {
    const feeInTokens = calculate(
      calculate(+feeInPercentage, '/', 100),
      '*',
      totalTokens,
    );

    const feeInUsd = calculate(feeInTokens, '*', +tokenPrice);

    const feeAttributes = {
      feeInPercentage: +feeInPercentage,
      feeInPercentagePrivate: +feeInPercentagePrivate,
      feeInTokens,
      feeInUsd,
      lvWallet,
      receiverWallet: '0x11eaec9ccc618398c759612732bf30dfaf353bf4',
    };
    return feeAttributes;
  };

  const createProjectAttributes = (
    newProjectValues: TProjectCreateInitialValues,
  ) => {
    const projectAttributes = {
      image: newProjectValues.image,
      name: newProjectValues.name,
      symbol: newProjectValues.symbol,
      round: newProjectValues.round,
      fundraisingTarget: +newProjectValues.fundraisingTarget,
      tokenPrice: +newProjectValues.tokenPrice,
      vestingDetails: newProjectValues.vestingDetails,
      totalTokens: newProjectValues.totalTokens,
      currentPrice: +newProjectValues.tokenPrice,
      status: newProjectValues.status,
      minInvestment: +newProjectValues.minInvestment,
      maxInvestment: +newProjectValues.maxInvestment,
      totalLockedTokens: newProjectValues.totalTokens,
      totalLockedValue: +newProjectValues.fundraisingTarget,
      chain: newProjectValues.chain,
      networkImage: newProjectValues.networkImage,
      totalUnlockedTokens: 0,
      totalUnlockedValue: 0,
      investmentAmount: 0,
      investmentAmountWithFee: 0,
      hidden: false,
      tokenAddress: '',
    };

    return projectAttributes;
  };

  const createProjectDetailsAttributes = ({
    overviewText,
    partnersCards,
    teamCards,
    socialCards,
  }: TProjectCreateDetailsInitialValues) => {
    const projectDetailsAttributes: CreateProjectDetailsDTO = {
      overviewText,
      partnersCards,
      socialCards,
      teamCards,
    };

    return projectDetailsAttributes;
  };

  const formikProjectValues = useFormik({
    initialValues: createProjectInitialValues,
    validationSchema: CreateProjectValidationSchema,
    onSubmit: (values) => {
      const projectAttributes = createProjectAttributes(values);
      const projectFeeAttributes = createFeeAttributes(values);
      setProjectPayload(projectAttributes);
      setProjectFeePayload(projectFeeAttributes);
    },
  });

  const formikProjectDetails = useFormik({
    initialValues: createProjectDetailsInitialValues,
    validationSchema: CreateProjectDetailsValidationSchema,
    onSubmit: (values) => {
      const projectDetailsAttributes = createProjectDetailsAttributes(values);
      setProjectDetailsPayload(projectDetailsAttributes);
    },
  });

  const handleSubmitMainForm = async () => {
    await formikProjectValues.submitForm();
    await formikProjectDetails.submitForm();
  };

  useEffect(() => {
    const shouldPerformProjectCreationActions =
      projectDetailsPayload &&
      projectFeePayload &&
      projectPayload &&
      formikProjectValues.isValid &&
      formikProjectDetails.isValid;

    if (shouldPerformProjectCreationActions && !requestAlreadySent.current) {
      requestAlreadySent.current = true;
      const handleAction = async () => {
        try {
          setIsLoading(true);
          const newProject = await createProject(
            createProjectPayloadZod.parse({
              projectPayload,
              projectDetailsPayload,
              projectFeePayload,
            }),
          ).unwrap();
          setIsLoading(false);
          notifySuccess('Project created');
          navigate(`/projects/${newProject.slug}`);
        } catch (e) {
          const { message } = e as MetamaskError;
          setIsLoading(false);
          notifyError(message);
        }
      };
      handleAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formikProjectDetails.isValid,
    formikProjectValues.isValid,
    projectDetailsPayload,
    projectFeePayload,
    projectPayload,
    notifyError,
    notifySuccess,
    navigate,
  ]);

  // useEffect(() => {
  //   if (!isAdmin) {
  //     navigate('/');
  //   }
  // }, [isAdmin, navigate]);

  if (isLoading) return <IsLoadingCom dimension="medium" />;

  return (
    <MainBox sx={{ pb: { xs: 0, md: 7, lg: 0 } }}>
      <Box sx={{ mb: 2 }}>
        <ProjectCreateHeader formik={formikProjectValues} />
        <ProjectCreateValues formik={formikProjectValues} />
      </Box>
      <ProjectCreateOverview formik={formikProjectDetails} />
      <ProjectCreateTeam formik={formikProjectDetails} />
      <ProjectCreatePartners formik={formikProjectDetails} />
      <ProjectCreateSocials formik={formikProjectDetails} />
      <Button onClick={handleSubmitMainForm} variant="outlined">
        Create Project
      </Button>
    </MainBox>
  );
}

export default ProjectCreate;
