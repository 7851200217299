import { MainBox } from '@styles/shared/SBoxes';
import LoginContent from '@components/modals/login/LoginContent';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import useUser from '@hooks/useUser';
import useUtils from '@hooks/useUtils';
import { useUpdateUserInfoMutation } from '@store/api/userApi';
import { useSearchParams } from 'react-router-dom';
import { updateUserInfoZod } from '@typescript/dtos/user/updateUserInfo.dto';

function Authenticate() {
  const { currentUser } = useUser();
  const { logOut } = useUtils();
  const [searchParams] = useSearchParams();
  const [updateUserInfo] = useUpdateUserInfoMutation();
  const telegramChatId = searchParams.get('telegramChatId');

  const executeLogout = async () => {
    await logOut(true);
  };

  useEffect(() => {
    if (currentUser) {
      executeLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser && telegramChatId) {
      const updateTelegramId = async () => {
        const { info } = currentUser;

        await updateUserInfo(
          updateUserInfoZod.parse({
            ...info,
            telegramChatId,
          }),
        );
      };

      updateTelegramId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <MainBox sx={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: '1',
        }}
      >
        <LoginContent />
      </Box>
    </MainBox>
  );
}

export default Authenticate;
