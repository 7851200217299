import { useState } from 'react';
import { Box } from '@mui/material';
import { TAllocation } from '@typescript/models/Allocation.model';
import useListings from '@hooks/useListings';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { MainBoxMargin } from '@styles/shared/SCss';
import { WTSListingInitialValues } from '@formik/modal/ModalInitialData';
import { WTSListingValidationSchema } from '@formik/modal/ModalValidation';
import WtsStep2Modal from '@components/modals/listings/WtsStep2Modal';
import WtsStep1Modal from '@components/modals/listings/WtsStep1Modal';
import SummaryHeader from '@components/shared/SummaryHeader';
import CardsView from '@components/portfolio/CardsView';
import DefaultPage from '@pages/Default';
import { TCreateWTSListingInitialValues } from '@typescript/TModalFormik';
import { Day } from '@typescript/models/Listing.model';
import { Svgs } from '@utils/svgs';
import DataWrapper from '@components/shared/DataWrapper';
import { NO_ALLOCATIONS_MS } from '@constants/CDefaultMessages';
import { createListingZod } from '@typescript/dtos/listing/createListing.dto';
import { UserAllocationStatsDTO } from '@typescript/dtos/allocation/getAllocation.dto';
import {
  useGetMyAllocationQuery,
  useGetUserAllocationStatsQuery,
} from '@store/api/allocationApi';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import useNotification from '@hooks/useNotification';
import { MainBox } from '@styles/shared/SBoxes';

function Portfolio() {
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(5);

  const { notifyError, notifySuccess } = useNotification();

  const {
    data: { resource: myAllocations, count } = { resource: [], count: 0 },
    isLoading: isLoadingAllocations,
  } = useGetMyAllocationQuery(
    paginationZod.parse({ page: page + 1, pageSize }),
  );

  const { data: myTotalAllocationsValues = {} as UserAllocationStatsDTO } =
    useGetUserAllocationStatsQuery({});

  const [openStep1Modal, setOpenStep1Modal] = useState<boolean>(false);

  const [openStep2Modal, setOpenStep2Modal] = useState<boolean>(false);

  const [steps, setSteps] = useState<1 | 2>(1);

  const [currentAllocation, setCurrentAllocation] = useState<TAllocation>();

  const { createWTS } = useListings();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onOpenModal = (allocation: TAllocation) => {
    setCurrentAllocation(allocation);
    setOpenStep1Modal(true);
    setSteps(1);
  };

  const changeSteps = (number: 1 | 2) => {
    setSteps(number);
  };

  const handleOnSubmitFormik = async (
    values: TCreateWTSListingInitialValues,
  ) => {
    try {
      if (currentAllocation) {
        setIsLoading(true);
        await createWTS(
          createListingZod.parse({
            amount: +values.tokenAmount,
            coins: values.coins,
            negotiable: values.negotiable,
            price: +values.totalCost,
            tokenPrice: +values.tokenPrice,
            projectId: currentAllocation.project.id,
            expireIn: +values.expireIn as Day,
            discordName: values.discordName,
            telegramLink: values.telegramLink,
          }),
        ).unwrap();
        notifySuccess('Listing created!');
        setIsLoading(false);
        setOpenStep1Modal(false);
        setOpenStep2Modal(false);
      }
    } catch (error) {
      setIsLoading(false);
      setOpenStep1Modal(false);
      setOpenStep2Modal(false);
      notifyError('Listing creation failed!');
      throw error;
    }
  };

  if (isLoading || isLoadingAllocations) {
    return <IsLoadingCom />;
  }
  if (myAllocations.length === 0) {
    return (
      <MainBox>
        <DefaultPage
          icon={Svgs.EmptyProjects}
          fullHeight
          text={NO_ALLOCATIONS_MS}
        />
      </MainBox>
    );
  }

  return (
    <MainBox sx={{ padding: 0 }}>
      <SummaryHeader myTotalAllocationsValues={myTotalAllocationsValues} />
      <DataWrapper
        isLoading={isLoadingAllocations}
        data={myAllocations}
        defaultOptions={{
          icon: Svgs.EmptyProjects,
          text: NO_ALLOCATIONS_MS,
        }}
        isLoadingOptions={{ dimension: 'contained' }}
      >
        <Box>
          <CardsView
            myAllocations={myAllocations}
            onOpenModal={onOpenModal}
            loadMore={() => {
              setPage(0);
              setPageSize(pageSize + 5);
            }}
            isLoading={isLoadingAllocations}
            count={count}
          />
          {/* {view === View.CARDS && myAllocations.length > 0 ? (
              <CardsView
                myAllocations={myAllocations || []}
                onOpenModal={onOpenModal}
                loadMore={() => {
                  setPage(1);
                  setPageSize(pageSize + 5);
                }}
                isLoading={isLoadingAllocations}
                count={count}
              />
            ) : (
              <Box paddingLeft="7rem" paddingRight="7rem">
                <DataGrid
                  rows={myAllocations || []}
                  sx={tableStyle(theme)}
                  columns={getActiveAllocationsColumns(onOpenModal)}
                  disableSelectionOnClick
                  autoHeight
                  loading={isLoading}
                  initialState={{
                    pagination: { pageSize, page },
                  }}
                  paginationMode="server"
                  rowCount={count}
                  onPageChange={(newPage) => setPage(newPage)}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20]}
                />
              </Box>
            )} */}
        </Box>
      </DataWrapper>
      {currentAllocation && (
        <Formik
          initialValues={WTSListingInitialValues}
          validate={(values) => {
            try {
              validateYupSchema(
                values,
                WTSListingValidationSchema(
                  currentAllocation.project.currentPrice,
                  currentAllocation.lockedTokens,
                ),
                true,
                values,
              );
            } catch (err) {
              return yupToFormErrors(err);
            }

            return {};
          }}
          onSubmit={(values) => handleOnSubmitFormik(values)}
        >
          <>
            {steps === 1 && openStep1Modal && currentAllocation && (
              <WtsStep1Modal
                openModal={openStep1Modal}
                closeModal={() => setOpenStep1Modal(false)}
                changeSteps={changeSteps}
                openWtsStep2Modal={() => setOpenStep2Modal(true)}
                project={currentAllocation.project}
              />
            )}
            {steps === 2 && openStep2Modal && currentAllocation && (
              <WtsStep2Modal
                openModal={openStep2Modal}
                closeModal={() => setOpenStep2Modal(false)}
                openWtsStep1Modal={() => setOpenStep1Modal(true)}
                changeSteps={changeSteps}
                project={currentAllocation.project}
              />
            )}
          </>
        </Formik>
      )}
    </MainBox>
  );
}

export default Portfolio;
