import { Box, Divider, Typography, useTheme } from '@mui/material';
import Default from '@pages/Default';
import { toRem } from '@utils/functions';

function Homepage() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '100vh',
        position: 'relative',
        background: `url(/bg-welcome.jpeg)`,
        backgroundSize: 'cover',

        '&>*': {
          position: 'relative',
          zIndex: 3,
        },

        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          background: theme.palette.background.default,
          zIndex: 2,
          opacity: 0.9,
        },
      }}
    >
      <Default
        showIcon={false}
        align="left"
        fullHeight
        text="Sark Family Office"
      >
        <>
          <Divider
            sx={{ position: 'static', mt: toRem(12), width: toRem(185) }}
          />
          <Typography
            sx={{ color: theme.palette.text.secondary, marginTop: toRem(16) }}
            variant="h3"
          >
            Welcome! As a single-family office, we are dedicated exclusively to
            managing and preserving the wealth and legacy of our beneficiaries.
            We are proud to be headquartered on the picturesque and independent
            Isle of Sark.
          </Typography>
        </>
      </Default>
    </Box>
  );
}

export default Homepage;
