import { Box, Typography, Container, useTheme } from '@mui/material';
import { Svgs } from '@utils/svgs';
import { flexCentered } from '@styles/shared/SCss';
import { toRem } from '@utils/functions';
import { NO_PAGE_MS } from '@constants/CDefaultMessages';

export interface DefaultComProps {
  text?: string;
  showIcon?: boolean;
  description?: string;
  fullHeight?: boolean;
  align?: 'left' | 'right' | 'center' | 'justify';
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  children?: React.ReactElement;
}

function Default({
  text = NO_PAGE_MS,
  description,
  children,
  showIcon = true,
  fullHeight,
  align = 'center',
  icon: Icon = Svgs.EmptyProjects,
}: DefaultComProps) {
  const theme = useTheme();
  const renderedSx = fullHeight
    ? { backgroundColor: 'transparent', height: '100%' }
    : {
        border: `1px solid ${theme.palette.secondary.light}80`,
        borderRadius: toRem(8),
        backgroundColor: theme.palette.background.default,
      };

  return (
    <Container
      sx={{
        '&.MuiContainer-maxWidth2lg': {
          height: fullHeight ? '100%' : 'auto',
          paddingLeft: fullHeight ? toRem(24) : '0',
          paddingRight: fullHeight ? toRem(24) : '0',
        },
      }}
      maxWidth="2lg"
    >
      <Box
        sx={{
          ...flexCentered,
          flexDirection: 'column',
          textAlign: align,
          padding: toRem(47),
          ...renderedSx,
          [theme.breakpoints.down('sm')]: {
            padding: 0,
          },
        }}
      >
        <Container maxWidth="sm">
          {showIcon && (
            <Box
              sx={{
                marginBottom: toRem(24),
              }}
            >
              <Icon />
            </Box>
          )}
          <Typography
            variant="h1"
            sx={{
              ...theme.typography.h2,
              fontWeight: '400',
              lineHeight: '1.5',
              fontSize: toRem(24),
            }}
          >
            {text}
          </Typography>

          {description && (
            <Typography
              sx={{
                fontWeight: '400',
                lineHeight: '1.5',
                color: theme.palette.text.secondary,
                textAlign: 'center',
                marginTop: toRem(12),

                [theme.breakpoints.down('sm')]: {
                  fontSize: '12px',
                },
              }}
            >
              {description}
            </Typography>
          )}

          {children && children}
        </Container>
      </Box>
    </Container>
  );
}

export default Default;
