import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MainBox, TabContainer } from '@styles/shared/SBoxes';
import { TabBtn, activeTabStyle } from '@styles/shared/SButtons';
import { TActivityTab } from '@typescript/TTabs';
import {
  ActivityStreamContentType,
  ActivityStreamContentTypeNames,
} from '@constants/CContentType';
import MyActivityInvestment from '@components/activity/InvestmentTable';
import MyActivityInvestmentResponsive from '@components/activity/responsive/MyActivityInvestmentResponsive';
import ActivityTables from '@components/activity/ActivityTables';
import ActivityTablesResponsive from '@components/activity/responsive/ActivityTablesResponsive';

function ActivityStream() {
  const theme = useTheme();

  const down2Lg = useMediaQuery(theme.breakpoints.down('2lg'));

  const navigate = useNavigate();

  const { tab: urlTab } = useParams();

  // const { data: stressTest } = useGetStressTestQuery({});

  // console.log(stressTest);

  const [activeTab, setActiveTab] = useState<TActivityTab>({
    index: 0,
    name: 'Bought',
  });

  const myActivityContent = () => {
    switch (true) {
      case activeTab.index === ActivityStreamContentType.INVESTED:
        if (down2Lg) {
          return <MyActivityInvestmentResponsive />;
        }
        return <MyActivityInvestment />;
      default:
        if (down2Lg) {
          return <ActivityTablesResponsive activeTab={activeTab} />;
        }
        return <ActivityTables activeTab={activeTab} />;
    }
  };

  useEffect(() => {
    if (!urlTab) {
      return;
    }

    if (
      urlTab &&
      Object.keys(ActivityStreamContentType).includes(urlTab.toUpperCase())
    ) {
      const currentTab = urlTab.toUpperCase() as ActivityStreamContentTypeNames;

      switch (currentTab) {
        case 'SOLD':
          setActiveTab({
            index: ActivityStreamContentType.SOLD,
            name: 'Sold',
          });
          break;
        case 'INVESTED':
          setActiveTab({
            index: ActivityStreamContentType.INVESTED,
            name: 'Invested',
          });
          break;
        default:
          setActiveTab({
            index: ActivityStreamContentType.BOUGHT,
            name: 'Bought',
          });
          break;
      }
    }
  }, [urlTab]);

  return (
    <MainBox sx={{ maxHeight: 'calc(100vh - 58px)', height: '100%' }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: '1920px',
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            padding: '1rem 0',
            position: { xs: 'sticky', '2lg': 'relative' },
            top: 0,
            backgroundColor: 'transparent',
            backdropFilter: 'blur(25px)',
            zIndex: 10,
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: 'primary.main', display: { xs: 'none', lg: 'block' } }}
          >
            Activity Stream{' '}
            <Box component="span" sx={{ opacity: 0.5 }}>
              {'>'} {activeTab.name}
            </Box>
          </Typography>
          <TabContainer sx={{ mt: { xs: 0, lg: 1 } }}>
            <TabBtn
              onClick={() => {
                setActiveTab({
                  index: ActivityStreamContentType.BOUGHT,
                  name: 'Bought',
                });
                navigate(`/activity-stream/bought`);
              }}
              sx={
                activeTab.index === ActivityStreamContentType.BOUGHT
                  ? activeTabStyle
                  : {}
              }
            >
              Bought
            </TabBtn>
            <TabBtn
              onClick={() => {
                setActiveTab({
                  index: ActivityStreamContentType.SOLD,
                  name: 'Sold',
                });
                navigate(`/activity-stream/sold`);
              }}
              sx={
                activeTab.index === ActivityStreamContentType.SOLD
                  ? activeTabStyle
                  : {}
              }
            >
              Sold
            </TabBtn>
            <TabBtn
              onClick={() => {
                setActiveTab({
                  index: ActivityStreamContentType.INVESTED,
                  name: 'Invested',
                });
                navigate(`/activity-stream/invested`);
              }}
              sx={
                activeTab.index === ActivityStreamContentType.INVESTED
                  ? activeTabStyle
                  : {}
              }
            >
              Invested
            </TabBtn>
          </TabContainer>
        </Box>
        <Box height="100%">{myActivityContent()}</Box>
      </Box>
    </MainBox>
  );
}

export default ActivityStream;
