import {
  partnersCardZod,
  socialCardZod,
  teamCardZod,
} from '@typescript/models/Project.model';
import { z } from 'zod';
import { createFeeZod } from '../fee/createFee.to';

const createProjectZod = z.object({
  name: z.string(),
  image: z.string(),
  status: z.number(),
  totalLockedTokens: z.number(),
  totalUnlockedTokens: z.number(),
  totalLockedValue: z.number(),
  totalUnlockedValue: z.number(),
  chain: z.string().optional(),
  networkImage: z.string(),
  round: z.string(),
  tokenPrice: z.number(),
  currentPrice: z.number(),
  tokenAddress: z.string().optional(),
  fundraisingTarget: z.number(),
  minInvestment: z.number(),
  maxInvestment: z.number(),
  investmentAmount: z.number(),
  investmentAmountWithFee: z.number(),
  totalTokens: z.number(),
  vestingDetails: z.string(),
  symbol: z.string().optional(),
  hidden: z.boolean(),
  merkleRoot: z.string().optional(),
});

const createProjectDetailsZod = z.object({
  overviewText: z.string(),
  partnersCards: z.array(partnersCardZod),
  teamCards: z.array(teamCardZod),
  socialCards: z.array(socialCardZod),
});

export const createProjectPayloadZod = z.object({
  projectPayload: createProjectZod,
  projectFeePayload: createFeeZod,
  projectDetailsPayload: createProjectDetailsZod,
});

export type CreateProjectDetailsDTO = z.infer<typeof createProjectDetailsZod>;

export type CreateProjectDTO = z.infer<typeof createProjectZod>;

export type CreateProjectPayloadDTO = z.infer<typeof createProjectPayloadZod>;
