import { Box, styled, BoxProps, useTheme, useMediaQuery } from '@mui/material';
import { ReactNode, useState } from 'react';
import { toRem } from '@utils/functions';
import Header from '@components/header/Header';
import LoginPopup from '@components/modals/login/LoginPopup';
import Sidebar from '@components/sidebar/Sidebar';

export const TabContainer = styled(Box)(({ theme }) => ({
  borderRadius: '6px',
  border: `1px solid ${theme.palette.secondary.light}80`,
  height: '32px',
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
}));

interface CustomBoxProps extends BoxProps {
  children: ReactNode;
}

export function MainBox({
  children,
  sx: propStyles = {},
  ...props
}: CustomBoxProps) {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const [leftOffset, setLeftOffset] = useState<number>(178);

  return (
    <>
      <Header />
      <Sidebar setLeftOffset={setLeftOffset} />
      <Box
        sx={{
          position: 'relative',
          paddingLeft: `${downLg ? 0 : leftOffset}px`,
          transition: `padding ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,

          '&:before, &:after': {
            content: '""',
            position: 'absolute',
            left: '0',
            right: '14px',
            height: toRem(88),
          },

          '&:before': {
            top: toRem(40),
            // eslint-disable-next-line max-len
            backgroundImage: `linear-gradient(0deg, ${theme.palette.background.default}00 0%, ${theme.palette.background.default} 100%)`,
            [theme.breakpoints.down('lg')]: {
              top: toRem(68),
            },
          },

          '&:after': {
            bottom: '0',
            // eslint-disable-next-line max-len
            backgroundImage: `linear-gradient(180deg, ${theme.palette.background.default}00 0%, ${theme.palette.background.default} 100%)`,
            [theme.breakpoints.down('md')]: {
              backgroundImage: 'none',
            },
          },
        }}
        {...props}
      >
        <Box
          sx={{
            padding: '32px 15px 15px 15px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',

            '&:after': {
              content: "''",
              height: toRem(103),
              width: '100%',
              flexShrink: '0',
            },

            [theme.breakpoints.down('lg')]: {
              marginTop: toRem(71),
              maxHeight: 'calc(100vh - 71px)',
            },
            ...propStyles,
          }}
          zIndex="2"
        >
          {children}
        </Box>
      </Box>
    </>
  );
}
