import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { ProjectContentType } from '@constants/CContentType';
import { TProjectTab } from '@typescript/TTabs';
import { MainBox, TabContainer } from '@styles/shared/SBoxes';
import { TabBtn, activeTabStyle } from '@styles/shared/SButtons';
import useStyles from '@hooks/useStyles';
import customColors from '@theme/customColors';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import DataWrapper from '@components/shared/DataWrapper';
import { Svgs } from '@utils/svgs';
import ProjectDealsCard from '@components/project/project-deals/ProjectDealsCard';
import { NO_PROJECTS_MS } from '@constants/CDefaultMessages';
import { useGetProjectsQuery } from '@store/api/projectApi';
import { ProjectStatus } from '@constants/CProject';
import { ProjectsContainer } from '@styles/project/SProjects';

// TODO: Fix this
const PAGE_SIZE = 100;

function ProjectCards({
  contentStatus,
}: {
  contentStatus: ProjectStatus | undefined;
}) {
  const {
    data: projectsData = { count: 0, resource: [] },
    isFetching,
    isLoading,
  } = useGetProjectsQuery({
    page: 1,
    pageSize: PAGE_SIZE,
    status: contentStatus,
  });

  return (
    <DataWrapper
      isLoading={isLoading || isFetching}
      data={projectsData.resource || []}
      defaultOptions={{
        icon: Svgs.EmptyProjects,
        fullHeight: true,
        text: NO_PROJECTS_MS,
      }}
    >
      <ProjectsContainer>
        {projectsData.resource.map((project) => (
          <ProjectDealsCard key={project.id} project={project} />
        ))}
      </ProjectsContainer>
    </DataWrapper>
  );
}

function Projects() {
  const theme = useTheme();

  const [projectsStatus, setProjectsStatus] = useState<ProjectContentType>(
    ProjectContentType.ALL,
  );

  const [tab, setTab] = useState<TProjectTab>({
    index: ProjectContentType.ALL,
    name: 'All',
  });

  const { border1pxSecondaryLight } = useStyles();

  const selectStyled = {
    borderRadius: '8px',
    width: '100%',
    maxWidth: '116px',
    border: border1pxSecondaryLight,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: 13,
    display: { xsm: 'table', lg: 'none' },
    '& .MuiSelect-select': {
      padding: '6px 30px 5px 8px',
    },
    '& .MuiSvgIcon-root': {
      marginTop: '2px',
      width: '20px',
      height: '20px',
      color: theme.palette.text.secondary,
    },
  };

  const SelectMenuProps: SxProps = {
    sx: {
      padding: '10px',
      top: '5px',
      '.MuiPaper-root': {
        left: '15px !important',
        minWidth: '116px !important',
        border: `1px solid ${theme.palette.secondary.light} !important`,
        borderRadius: '8px',
        background: 'transparent',
        boxShadow: `0px 12px 14px 0px ${customColors.boxShadow45}`,
      },
      '.MuiList-root': {
        padding: '5px',
        background: customColors.darkBlue70,
        backdropFilter: 'blur(20px)',
        '& .MuiButtonBase-root + .MuiButtonBase-root': {
          marginTop: '5px',
        },
      },
      '.Mui-selected': {
        backgroundColor: `${theme.palette.primary.contrastText} !important`,
        color: `${theme.palette.text.primary} !important`,
      },
      '.MuiButtonBase-root': {
        padding: '5px',
        color: theme.palette.primary.dark,
        borderRadius: '4px',
        fontSize: 13,
        minHeight: 'auto !important',
        ':hover': {
          backgroundColor: theme.palette.primary.contrastText,
        },
      },
    },
  };

  const changeProjectsStatus = (event: SelectChangeEvent) => {
    setProjectsStatus(+event.target.value as ProjectContentType);
  };

  const changeTabIndex = (currentTab: TProjectTab) => {
    setTab(currentTab);
    setProjectsStatus(currentTab.index);
  };

  return (
    <MainBox className="Main-Box">
      <Box>
        <TabContainer
          mt={1}
          sx={{
            border: border1pxSecondaryLight,
            display: { xs: 'none', lg: 'table' },
            top: '10px',
            position: 'sticky',
          }}
        >
          <TabBtn
            disableRipple
            onClick={() =>
              changeTabIndex({
                index: ProjectContentType.ALL,
                name: 'All',
              })
            }
            sx={tab.index === ProjectContentType.ALL ? activeTabStyle : {}}
          >
            All
          </TabBtn>
          <TabBtn
            disableRipple
            onClick={() =>
              changeTabIndex({
                index: ProjectContentType.NOT_LAUNCHED,
                name: 'Not Launched',
              })
            }
            sx={
              tab.index === ProjectContentType.NOT_LAUNCHED
                ? activeTabStyle
                : {}
            }
          >
            Not Launched
          </TabBtn>
          <TabBtn
            disableRipple
            onClick={() =>
              changeTabIndex({
                index: ProjectContentType.LIVE,
                name: 'Live',
              })
            }
            sx={tab.index === ProjectContentType.LIVE ? activeTabStyle : {}}
          >
            Live
          </TabBtn>
          {/* <TabBtn
            disableRipple
            onClick={() =>
              changeTabIndex({
                index: ProjectContentType.LIVE_PRIVATE,
                name: 'Live Private',
              })
            }
            sx={
              tab.index === ProjectContentType.LIVE_PRIVATE
                ? activeTabStyle
                : {}
            }
          >
            Live Private
          </TabBtn> */}
          <TabBtn
            disableRipple
            onClick={() =>
              changeTabIndex({
                index: ProjectContentType.DISTRIBUTING,
                name: 'Distributing',
              })
            }
            sx={
              tab.index === ProjectContentType.DISTRIBUTING
                ? activeTabStyle
                : {}
            }
          >
            Distributing
          </TabBtn>
          <TabBtn
            disableRipple
            onClick={() =>
              changeTabIndex({
                index: ProjectContentType.COMPLETED,
                name: 'Completed',
              })
            }
            sx={
              tab.index === ProjectContentType.COMPLETED ? activeTabStyle : {}
            }
          >
            Completed
          </TabBtn>
        </TabContainer>
      </Box>
      <Select
        value={projectsStatus.toString()}
        onChange={changeProjectsStatus}
        sx={selectStyled}
        MenuProps={SelectMenuProps}
        IconComponent={ExpandMoreRoundedIcon}
      >
        <MenuItem disableGutters disableRipple value={ProjectContentType.ALL}>
          All
        </MenuItem>
        <MenuItem
          disableGutters
          disableRipple
          value={ProjectContentType.NOT_LAUNCHED}
        >
          Not Launched
        </MenuItem>
        <MenuItem disableGutters disableRipple value={ProjectContentType.LIVE}>
          Live
        </MenuItem>
        <MenuItem disableGutters disableRipple value={ProjectContentType.LIVE}>
          Live Private
        </MenuItem>
        <MenuItem
          disableGutters
          disableRipple
          value={ProjectContentType.DISTRIBUTING}
        >
          Distributing
        </MenuItem>
        <MenuItem
          disableGutters
          disableRipple
          value={ProjectContentType.COMPLETED}
        >
          Completed
        </MenuItem>
      </Select>

      {projectsStatus === ProjectContentType.ALL && (
        <ProjectCards contentStatus={undefined} />
      )}
      {projectsStatus === ProjectContentType.NOT_LAUNCHED && (
        <ProjectCards contentStatus={ProjectStatus.NOT_LAUNCHED} />
      )}
      {projectsStatus === ProjectContentType.LIVE && (
        <ProjectCards contentStatus={ProjectStatus.LIVE} />
      )}
      {projectsStatus === ProjectContentType.DISTRIBUTING && (
        <ProjectCards contentStatus={ProjectStatus.DISTRIBUTING} />
      )}
      {projectsStatus === ProjectContentType.COMPLETED && (
        <ProjectCards contentStatus={ProjectStatus.COMPLETED} />
      )}
    </MainBox>
  );
}

export default Projects;
