import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import Moralis from 'moralis-v1';

import {
  GetWhitelistsDTO,
  getWhitelistsZod,
} from '@typescript/dtos/whitelists/getWhitelists.dto';

export const whitelistsApi = createApi({
  reducerPath: ApiSlice.WHITELISTS,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.WHITELISTS],
  endpoints: (builder) => ({
    getWhitelists: builder.query({
      async queryFn() {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_WHITELISTS,
          );
          return {
            data: getWhitelistsZod.parse(response),
          };
        } catch (error) {
          return { data: undefined };
        }
      },
      providesTags: [ApiTag.WHITELISTS],
    }),
    updateWhitelists: builder.mutation({
      async queryFn(dto: GetWhitelistsDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_WHITELISTS,
            { addresses: dto },
          );
          return { data: getWhitelistsZod.parse(response) };
        } catch (error) {
          throw Error(`updateWhitelists: ${error}`);
        }
      },
    }),
  }),
});

export const {
  useUpdateWhitelistsMutation,
  useLazyGetWhitelistsQuery,
  useGetWhitelistsQuery,
} = whitelistsApi;
