import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import Moralis from 'moralis-v1';
import { IdDTO } from '@typescript/dtos/shared/id.dto';
import {
  projectAllocationStatsZod,
  userAllocationStatsZod,
} from '@typescript/dtos/allocation/getAllocation.dto';
import {
  resourceWithPaginatedZod,
  PaginationDTO,
} from '@typescript/dtos/shared/pagination.dto';
import { allocationZod } from '@typescript/models/Allocation.model';

export const allocationApi = createApi({
  reducerPath: ApiSlice.ALLOCATIONS,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.ALLOCATIONS],
  endpoints: (builder) => ({
    getMyAllocation: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_ALLOCATIONS,
            payload,
          );

          return {
            data: resourceWithPaginatedZod(allocationZod).parse(response),
          };
        } catch (error) {
          throw Error(`getMyAllocation: ${error}`);
        }
      },
      providesTags: [ApiTag.ALLOCATIONS],
    }),
    getActiveAllocation: builder.query({
      async queryFn() {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_ACTIVE_ALLOCATIONS,
          );
          return { data: allocationZod.array().parse(response) };
        } catch (error) {
          throw Error(`getActiveAllocation: ${error}`);
        }
      },
      providesTags: [ApiTag.ALLOCATIONS],
    }),
    getAllocationByProject: builder.query({
      async queryFn(payload: IdDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_ALLOCATION_BY_PROJECT,
            payload,
          );
          return { data: allocationZod.parse(response) };
        } catch (error) {
          throw Error(`getAllocationByProject: ${error}`);
        }
      },
      providesTags: [ApiTag.ALLOCATIONS],
    }),
    getAllocationByListing: builder.query({
      async queryFn(payload: IdDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_ALLOCATION_BY_LISTING,
            payload,
          );
          return { data: allocationZod.parse(response) };
        } catch (error) {
          throw Error(`getAllocationByListing: ${error}`);
        }
      },
      providesTags: [ApiTag.ALLOCATIONS],
    }),
    getUserAllocationStats: builder.query({
      async queryFn() {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_USER_ALLOCATION_STATS,
          );
          return { data: userAllocationStatsZod.parse(response) };
        } catch (error) {
          throw Error(`getUserAllocationStats: ${error}`);
        }
      },
      providesTags: [ApiTag.ALLOCATIONS],
    }),
    getProjectAllocationStats: builder.query({
      async queryFn() {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_PROJECT_ALLOCATION_STATS,
          );
          return { data: projectAllocationStatsZod.parse(response) };
        } catch (error) {
          throw Error(`getProjectAllocationStats: ${error}`);
        }
      },
      providesTags: [ApiTag.ALLOCATIONS],
    }),
  }),
});

export const {
  useLazyGetActiveAllocationQuery,
  useGetMyAllocationQuery,
  useGetAllocationByProjectQuery,
  useGetUserAllocationStatsQuery,
} = allocationApi;
